import React from "react"
import { graphql } from "gatsby"

import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const doom = data?.doom?.childImageSharp?.fixed

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />      
      <div style={{textAlign:`center`}}>
        <h1>404: Not Found</h1>
        <Image fixed={doom} alt="doom_creep" />
        <p><em>"Nothing to see here!"</em></p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    doom: file(absolutePath: { regex: "/404.png/" }) {
      childImageSharp {
        fixed(quality: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
